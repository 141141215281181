import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../../components/layout"
import Img from "gatsby-image"
import SEO from "../../components/seo"

const OnlineProducts = () => {
  const data = useStaticQuery(graphql`
    query {
      boneBroth: file(relativePath: { eq: "farm-business/online-products/bone-broth.jpg" }) {
        childImageSharp {
          fixed {
            ...GatsbyImageSharpFixed
          }
        }
      },
      equineVTM: file(relativePath: { eq: "farm-business/online-products/equine-vtm.jpg" }) {
        childImageSharp {
          fixed {
            ...GatsbyImageSharpFixed
          }
        }
      },
      henTreats: file(relativePath: { eq: "farm-business/online-products/hen-treats.jpg" }) {
        childImageSharp {
          fixed {
            ...GatsbyImageSharpFixed
          }
        }
      },
      immuneBoost: file(relativePath: { eq: "farm-business/online-products/immune-boost.jpg" }) {
        childImageSharp {
          fixed {
            ...GatsbyImageSharpFixed
          }
        }
      },
      instantGoatsMilk: file(relativePath: { eq: "farm-business/online-products/instant-goats-milk.jpg" }) {
        childImageSharp {
          fixed {
            ...GatsbyImageSharpFixed
          }
        }
      },
      mealWorms: file(relativePath: { eq: "farm-business/online-products/meal-worms.jpg" }) {
        childImageSharp {
          fixed {
            ...GatsbyImageSharpFixed
          }
        }
      },
      poultryBase: file(relativePath: { eq: "farm-business/online-products/poultry-base.jpg" }) {
        childImageSharp {
          fixed {
            ...GatsbyImageSharpFixed
          }
        }
      },
      rabbitTreat: file(relativePath: { eq: "farm-business/online-products/rabbit-treat.jpg" }) {
        childImageSharp {
          fixed {
            ...GatsbyImageSharpFixed
          }
        }
      },
      tShirts: file(relativePath: { eq: "farm-business/online-products/t-shirts.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      },
    }
  `)
  return (
    <Layout>
      <SEO title="Online Products" />
      <br />
      <h1>Online Products</h1>
      <div>
        <div class="row">
          <div class="col-lg-4 col-sm-6">
            <p><strong>Immune Boost</strong><br />
              <Img alt="Immune Boost" fixed={data.immuneBoost.childImageSharp.fixed} /><br />
              A water soluble source of vitamins, trace minerals, electrolytes, and beneficial bacteria, formulated to help keep chicks and chickens healthy during stress.
            </p>
            <form target="paypal" action="https://www.paypal.com/cgi-bin/webscr" method="post">
              <input type="hidden" name="cmd" value="_s-xclick" />
              <input type="hidden" name="hosted_button_id" value="H4QF3NZWL4SJN" />
              <table>
                <tbody><tr><td><select name="os0">
                  <option value="1 Pouch">1 Pouch $11.99 USD</option>
                  <option value="2 Pouches">2 Pouches $23.98 USD</option>
                  <option value="3 Pouches">3 Pouches $35.97 USD</option>
                  <option value="4 Pouches">4 Pouches $47.96 USD</option>
                </select> </td></tr>
                </tbody></table>
              <input type="hidden" name="currency_code" value="USD" />
              <input class="mt-2 mb-3" type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_cart_LG.gif" border="0" name="submit" alt="PayPal - The safer, easier way to pay online!" />
              <img alt="" border="0" src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif" width="1" height="1" />
            </form>
          </div>
          <div class="col-lg-4 col-sm-6">
            <p><strong>Meal Worms 20 oz</strong><br />
              <Img alt="Meal Worms" fixed={data.mealWorms.childImageSharp.fixed} /><br />
              Perfect high protein treat for all birds new and old.<br />
              $15.99</p>
            <form target="paypal" action="https://www.paypal.com/cgi-bin/webscr" method="post">
              <input type="hidden" name="cmd" value="_s-xclick" />
              <input type="hidden" name="hosted_button_id" value="WD39JGJGFJLFA" />
              <input class="mb-3" type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_cart_LG.gif" border="0" name="submit" alt="PayPal - The safer, easier way to pay online!" />
              <img alt="" border="0" src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif" width="1" height="1" />
            </form>
          </div>
          <div class="col-lg-4 col-sm-6">
            <p><strong>Rabbit Treat 1 lb</strong><br />
              <Img alt="Rabbit Treat" fixed={data.rabbitTreat.childImageSharp.fixed} /><br />
              Perfect healthy rabbit treat, big and small. Includes rolled corn and barley, banana chips, and carrot dices.</p>
            <form target="paypal" action="https://www.paypal.com/cgi-bin/webscr" method="post">
              <input type="hidden" name="cmd" value="_s-xclick" />
              <input type="hidden" name="hosted_button_id" value="AXKAMJ6B69DL6" />
              <table>
                <tbody>
                  <tr>
                    <td>
                      <select name="os0">
                        <option value="1 Pouch">1 Pouch $5.99 USD</option>
                        <option value="2 Pouches">2 Pouches $11.98 USD</option>
                      </select>
                    </td>
                  </tr>
                </tbody>
              </table>
              <input type="hidden" name="currency_code" value="USD" />
              <input class="mt-2 mb-3" type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_cart_LG.gif" border="0" name="submit" alt="PayPal - The safer, easier way to pay online!" />
              <img alt="" border="0" src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif" width="1" height="1" />
            </form>
          </div>
          <div class="col-lg-4 col-sm-6">
            <p><strong>Hen Treats 10 lbs</strong><br />
              <Img alt="Hen Treats" fixed={data.henTreats.childImageSharp.fixed} /><br />
              Red wheat, rolled corn, milo, rolled oats, green peas, oats groats, Meal Worms and oyster shell.<br />
              $24.99</p>
            <form target="paypal" action="https://www.paypal.com/cgi-bin/webscr" method="post">
              <input type="hidden" name="cmd" value="_s-xclick" />
              <input type="hidden" name="hosted_button_id" value="LQ5BKTVKU3R2G" />
              <input class="mb-3" type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_cart_LG.gif" border="0" name="submit" alt="PayPal - The safer, easier way to pay online!" />
              <img alt="" border="0" src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif" width="1" height="1" />
            </form>
          </div>
          <div class="col-lg-4 col-sm-6">
            <p><strong>Instant Goats Milk</strong><br />
              <Img alt="Instant Goats Milk" fixed={data.instantGoatsMilk.childImageSharp.fixed} /><br />
              A natural supplement with probiotics and enzymes for digestive.<br />
              $17.99</p>
            <form target="paypal" action="https://www.paypal.com/cgi-bin/webscr" method="post">
              <input type="hidden" name="cmd" value="_s-xclick" />
              <input type="hidden" name="hosted_button_id" value="GVDVA83UB8LWQ" />
              <input class="mb-3" type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_cart_LG.gif" border="0" name="submit" alt="PayPal - The safer, easier way to pay online!" />
              <img alt="" border="0" src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif" width="1" height="1" />
            </form>
          </div>
          <div class="col-lg-4 col-sm-6">
            <p><strong>Bone Broth</strong><br />
              <Img alt="Bone Broth" fixed={data.boneBroth.childImageSharp.fixed} /><br />
              A nourishing gourmet mix to create your own instant homemade bone broth.<br />
              $12.99</p>
            <form target="paypal" action="https://www.paypal.com/cgi-bin/webscr" method="post">
              <input type="hidden" name="cmd" value="_s-xclick" />
              <input type="hidden" name="hosted_button_id" value="9A73N7K2Q44VS" />
              <input class="mb-3" type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_cart_LG.gif" border="0" name="submit" alt="PayPal - The safer, easier way to pay online!" />
              <img alt="" border="0" src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif" width="1" height="1" />
            </form>
          </div>
          <div class="col-lg-4 col-sm-6">
            <p><strong>Poultry Base Premix</strong><br />
              <Img alt="Poultry Base Premix" fixed={data.poultryBase.childImageSharp.fixed} /><br />
              Premix is designed to be mixed with grains and proteins to make your own Poultry feed. This 7lb premix is a complete vitamin mix for 189 lbs of feed.</p>
            <form target="paypal" action="https://www.paypal.com/cgi-bin/webscr" method="post">
              <input type="hidden" name="cmd" value="_s-xclick" />
              <input type="hidden" name="hosted_button_id" value="FAVC6TKTHXJ8S" />
              <table>
                <tbody><tr><td><select name="os0">
                  <option value="7 lbs (incl. shipping)">7 lbs (incl. shipping) $34.98 USD</option>
                  <option value="14 lbs (incl. shipping)">14 lbs (incl. shipping) $61.97 USD</option>
                </select> </td></tr>
                </tbody></table>
              <input type="hidden" name="currency_code" value="USD" />
              <input class="mt-2 mb-3" type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_cart_LG.gif" border="0" name="submit" alt="PayPal - The safer, easier way to pay online!" />
              <img alt="" border="0" src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif" width="1" height="1" />
            </form>
          </div>
          <div class="col-lg-4 col-sm-6">
            <p><strong>Equine VTM Premix 128oz</strong><br />
              <Img alt="Equine VTM Premix" fixed={data.equineVTM.childImageSharp.fixed} /><br />
              Premix is designed to be mixed at the rate of 2 to 3 ounces per head per day. Roughly 64 days of complete vitamins.</p>
            <form target="paypal" action="https://www.paypal.com/cgi-bin/webscr" method="post">
              <input type="hidden" name="cmd" value="_s-xclick" />
              <input type="hidden" name="hosted_button_id" value="NL9H28U2YNL9Q" />
              <table>
                <tbody><tr><td><select name="os0">
                  <option value="7 lbs (incl. shipping)">7 lbs (incl. shipping) $32.98 USD</option>
                  <option value="14 lbs (incl. shipping)">14 lbs (incl. shipping) $59.97 USD</option>
                </select> </td></tr>
                </tbody></table>
              <input type="hidden" name="currency_code" value="USD" />
              <input class="mt-2 mb-3" type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_cart_LG.gif" border="0" name="submit" alt="PayPal - The safer, easier way to pay online!" />
              <img alt="" border="0" src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif" width="1" height="1" />
            </form>
          </div>
          <div class="col-lg-4 col-sm-6">
            <p><strong>Digestive Supplement Perfect Form</strong><br />
              Ideal for cats and dogs with gastrointestinal upset and less-than-perfect digestion. Filled with herbs like slippery elm, fennel and plantain to soothe and protect the GI tract, Perfect Form aids in reducing occasional loose stools from environmental stress, dietary indiscretion or the transition to a new diet. It's also helpful for pets with gas and regularity issues—don't laugh, it's a real problem.<br />
              $12.99</p>
            <form target="paypal" action="https://www.paypal.com/cgi-bin/webscr" method="post">
              <input type="hidden" name="cmd" value="_s-xclick" />
              <input type="hidden" name="hosted_button_id" value="A55EFC9G8Z3DY" />
              <input class="mb-3" type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_cart_LG.gif" border="0" name="submit" alt="PayPal - The safer, easier way to pay online!" />
              <img alt="" border="0" src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif" width="1" height="1" />
            </form>
          </div>
        </div>
        <p><strong>Baxter Barn T-Shirts</strong></p>
        <Img alt="Baxter Barn T-Shirts" fluid={data.tShirts.childImageSharp.fluid} /><br />
        <div class="row">
          <div class="col">
            <p><strong>Mens</strong></p>
            <form target="paypal" action="https://www.paypal.com/cgi-bin/webscr" method="post">
              <input type="hidden" name="cmd" value="_s-xclick" />
              <input type="hidden" name="hosted_button_id" value="69QM3RJMGRKUW" />
              <table>
                <tbody><tr><td><input type="hidden" name="on0" value="Sizes" />Sizes</td></tr><tr><td><select name="os0">
                  <option value="Small">Small $19.95 USD</option>
                  <option value="Medium">Medium $19.95 USD</option>
                  <option value="Large">Large $19.95 USD</option>
                  <option value="X-Large">X-Large $24.95 USD</option>
                </select> </td></tr>
                  <tr><td><input type="hidden" name="on1" value="T-Shirt Phrase" />T-Shirt Phrase</td></tr><tr><td><select name="os1">
                    <option value="CHICKS DIG ME">CHICKS DIG ME </option>
                    <option value="BAD EGG">BAD EGG </option>
                    <option value="QUIT YOUR SQUAWKING">QUIT YOUR SQUAWKING </option>
                    <option value="THESE ARE MY PEEPS">THESE ARE MY PEEPS </option>
                  </select> </td></tr>
                  <tr><td><input type="hidden" name="on2" value="Color" />Color</td></tr><tr><td><select name="os2">
                    <option value="Brown">Brown </option>
                    <option value="Natural (Off White)">Natural (Off White) </option>
                  </select> </td></tr>
                </tbody></table>
              <input type="hidden" name="currency_code" value="USD" />
              <input class="mt-2 mb-3" type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_cart_LG.gif" border="0" name="submit" alt="PayPal - The safer, easier way to pay online!" />
              <img alt="" border="0" src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif" width="1" height="1" />
            </form>
          </div>
          <div class="col">
            <p><strong>Women's (V-Neck)</strong></p>
            <form target="paypal" action="https://www.paypal.com/cgi-bin/webscr" method="post">
              <input type="hidden" name="cmd" value="_s-xclick" />
              <input type="hidden" name="hosted_button_id" value="222HT8J4BAGYQ" />
              <table>
                <tbody><tr><td><input type="hidden" name="on0" value="Sizes" />Sizes</td></tr><tr><td><select name="os0">
                  <option value="Small">Small $19.95 USD</option>
                  <option value="Medium">Medium $19.95 USD</option>
                  <option value="Large">Large $19.95 USD</option>
                  <option value="X-Large">X-Large $24.95 USD</option>
                </select> </td></tr>
                  <tr><td><input type="hidden" name="on1" value="T-Shirt Phrase" />T-Shirt Phrase</td></tr><tr><td><select name="os1">
                    <option value="CHICKS DIG ME">CHICKS DIG ME </option>
                    <option value="BAD EGG">BAD EGG </option>
                    <option value="QUIT YOUR SQUAWKING">QUIT YOUR SQUAWKING </option>
                    <option value="THESE ARE MY PEEPS">THESE ARE MY PEEPS </option>
                  </select> </td></tr>
                  <tr><td><input type="hidden" name="on2" value="Color" />Color</td></tr><tr><td><select name="os2">
                    <option value="Brown">Brown </option>
                    <option value="Natural (Off White)">Natural (Off White) </option>
                  </select> </td></tr>
                </tbody></table>
              <input type="hidden" name="currency_code" value="USD" />
              <input class="mt-2 mb-3" type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_cart_LG.gif" border="0" name="submit" alt="PayPal - The safer, easier way to pay online!" />
              <img alt="" border="0" src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif" width="1" height="1" />
            </form>
          </div>
        </div>
        <p class="text-center mt-5"><strong>All products are available at Baxter Barn.</strong></p>
      </div>
    </Layout>
  )
}

export default OnlineProducts
